/*----------------------------*/
     /*main body*/
/*----------------------------*/

html, body {
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Sofia"
    "Ubuntu", "CantareSll", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #bfbfbf;
}

.App {
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  margin: 0 auto;
  background:  #737373;
  overflow:hidden;
}
.appBarWord {
  display: inline;
  font-size: 10rem;
  font-family: Allerta Stencil;
  color: white;
  padding-left: 25%;
}
@media only screen and (max-width: 768px) {
  .appBarWord {
    font-size: 2rem;
    padding-left: 0%;
  }
}

/*----------------------------*/
     /*Nave page*/
/*----------------------------*/

.Nav {
  width: 100%;
  display: flex;
  height: 80px;
  justify-content:space-around;
  padding-left: 12%;
  padding-right: 12%;
  background-color:  #42a4f5;
}

.Nav-menu {
  width: 120px;
  height: 45px;
  color:#ffffff;
  font-size: 2rem;
  cursor: pointer;
  background-color: #595959;
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid #0d0d0d;
  transition: 1s;
  text-align: center;
  vertical-align: middle;
  padding: .5rem;
  margin: 0.5rem;
}

.Nav-menu:hover, .Nav-menu:focus {
  background-color: #03A6A6;
  border-color: #03A6A6;
  color: #011826;
  box-shadow: inset 0 -6rem 0 #03A6
}

/*----------------------------*/
     /*Home page*/
/*----------------------------*/

.Intro {
  width: 60%;
  display: flex;
  color: #003d99;
  text-align: center;
  font-family: 'Fugaz One', cursive;
  font-size: 3.5rem;
  text-shadow: -10px 5px 5px #ffffff;
  margin-top: 10%;
  margin-left: 12%;
  position: absolute;
  z-index: 1;
}

  /*mobile size */

  @media only screen and (max-width: 768px) {
    .Intro {
      font-size: 1.8rem;
    }
  }

/*----------------------------*/
     /*About Page*/
/*----------------------------*/
.about {
  display: flex;
}
.abouTeddyInt {
  width: 50%;
  text-align: left;
  font-family: Sofia;
  font-size: 2rem;
  color: #003d99 
}
.abouTeddyInt p {
  font-size: 1.8rem;
  color: #003d99 
}
@media only screen and (max-width: 568px) {
  .abouTeddyInt {
    width: 100%;
  }
  .about {
    display: inline;
  }
}
/*----------------------------*/
     /*Project Page*/
/*----------------------------*/
.projeects-title {
  font-size: 2.5rem;
  color: #003d99
}
.projects {
  width: 45%;
  color: #003d99;
  font-family: Fugaz One;
  padding: 3%,  
}

.project {
  width: 90%;
  margin-top: 3%
}
.iframeContainer {
  width: 50%;
}

/*mobile size */
@media only screen and (max-width: 768px) {
  .projeects-title {
    font-size: 1.5rem;
    color: #003d99
  }
  .projects {
    width: 100%;
  }
  .project {
    width: 90%;
    margin-top: 3%
  }
  .iframeContainer {
    display: none;
  }
  .projectLink {
    display: none;
  }
}
/*----------------------------*/
     /*Resume Page*/
/*----------------------------*/

.cv {
  width: 80%;
}

/*----------------------------*/
     /*Contact Page*/
/*----------------------------*/
.contactRaper {
  width: 80%;
  display: flex;
  color: #003d99;
  font-size: 2rem;
  border-radius: 20px;
  margin-top: 5%;
  margin-left: 10%;
  background: #808080;
  margin-bottom: 10%;
  padding: 5%
}
.contactMessage {
  width: 48%;
}
.contactInfo {
  width: 48%;
  margin-left: 10%
}
.input {
  width: 60%;
  font-size: 1.5rem;
  border-radius: 10px;
  background: #e6e6e6;
  margin-left: 2%
}
.inputMessage {
  width: 60%;
  font-size: 1.5rem;
  border-radius: 10px;
  background: #e6e6e6;
  margin-left: 30%;
}

/*mobile size */

@media only screen and (max-width: 768px) {
  .contactRaper {
    display: flex;
    flex-direction: column;
  }
  .contactMessage {
    width: 90%;
  }
  .contactInfo {
    width: 90%;
    margin-left: 10%
  }
  .input {
    width: 80%;
    font-size: 1.5rem;
    border-radius: 10px;
    background: #e6e6e6;
    margin-left: 15%
  }
  .inputMessage {
    font-size: 1.5rem;
    border-radius: 10px;
    background: #e6e6e6;
    margin-left: 15%;
    width: 80%
  }
}
/*----------------------------*/
     /*Hobby Page*/
/*----------------------------*/
.hobby-wrapper {
  display: flex;
  width: 80vw;
  height: 100vh;
  color: #808080;
}
.slider-wrapper {
    width: 80vw;
    position: relative;
    height: 70vh;
    overflow: hidden;
    margin: 3%;
  }
.slider-content {
      width: 90vw;
      margin-right: 5%;
      padding-right: 5%;
      text-align: center;
}
.inner {
        padding: 0 70px;
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
}
.inner h2 {
          font-weight: 700;
          margin: 0 auto;
          max-width: 840px;
          color: #FFFFFF;
          font-size: 54px;
          line-height: 1;
}
.inner  p {
          color: #FFFFFF;
          font-size: 15px;
          line-height: 1.5;
          margin: 20px auto 30px;
          max-width: 640px;
}
.slide {
    height: 50vh;
    background-size: cover !important;
}
.slide::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 80%;
      background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, 0.9)));
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
      bottom: 0;
      left: 0;
}
.previousButton, .nextButton {
      position: absolute;
      left: 50%;
      transform: translateY(-50%);
      z-index: 10;
      background: url('./img/arrow.png') no-repeat center center / 25px;
      width: 40px;
      height: 40px;
      text-indent: -9999px;
      cursor: pointer;
}
.previousButton {
    top: 15px;
    -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
            transform: rotate(180deg) translateY(calc(50% + 0px));
}
.previousButton:hover {
    width: 30px;
    height: 30px;
}
  
.nextButton {
    bottom: 15px;
    -webkit-transform: rotate(360deg) translateY(calc(50% + 0px));
            transform: rotate(360deg) translateY(calc(50% + 0px));
}
  
.nextButton:hover {
    width: 30px;
    height: 30px;
}
.slide h1 {
    transition: all 0.3s ease;
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
    opacity: 0;
}
  
.slide p {
    transition: all 0.3s ease;
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    opacity: 0;
}
.slide.animateIn.previous h1,
.slide.current h1,
.slide.animateIn.next h1,
.slide.animateIn.previous p,
.slide.current p,
.slide.animateIn.next p {
	-webkit-transform: translateX(0);
	        transform: translateX(0);
	-webkit-transition-delay: .9s;
					transition-delay: .9s;
					opacity: 1;
}

.slide.animateIn.previous p,
.slide.animateIn.next p {
	  -webkit-transition-delay: 1.1s;
					transition-delay: 1.1s;
}

   /*tablet size */
@media (max-height: 800px) {
      .slider-wrapper, .slide {
        width: 100%;
        height: calc(100vh - 10px);
      }  
      .inner h2 {
        font-weight: 600;
        margin: 0 auto;
        max-width: 740px;
        color: #FFFFFF;
        font-size: 25px;
        line-height: 1;
      }  
}
